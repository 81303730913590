<template>
  <v-dialog v-model="open" width="360" height="404">
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-textarea
          v-model="childDesc"
          :placeholder="placeholder"
          outlined
          :disabled="!rolesCheck('allocationByIdEdit')"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel">取消</v-btn>
        <v-btn
          color="primary"
          @click="$emit('submit', childDesc)"
          :disabled="!rolesCheck('allocationByIdEdit')"
          >確認</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { checkRolesMixin } from "@/helper";
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    Tdesc: {
      type: String,
      default: "",
    },
    Tkey: {
      type: String,
      default: "",
    },
  },
  mixins: [checkRolesMixin],
  data() {
    return {
      title: "",
      placeholder: "",
      childDesc: "",
    };
  },
  mounted() {
    if (this.Tkey == "rf") {
      this.title = "監管重點領域清單";
      this.placeholder = "請填寫監管重點領域";
    } else if (this.Tkey == "gr") {
      this.title = "集團高風險清單";
      this.placeholder = "請填寫高風險領域";
    }
    this.childDesc = this.Tdesc;
  },
  methods: {
    cancel() {
      this.$emit("close");
      this.childDesc = this.Tdesc;
    },
  },
};
</script>
