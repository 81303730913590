<template>
  <v-card tile style="width: auto" class="elevation-1" :min-width="minWidth">
    <div class="d-inline-flex" style="width: -webkit-fill-available">
      <v-card
        v-for="i in items"
        :key="i.text"
        class="elevation-0"
        height="fit-content"
        :style="`width: ${i.width ? i.width + 'px' : 'inherit'};`"
        tile
      >
        <v-card-title
          class="settingTitle text-no-wrap py-2"
          :style="`width: ${
            i.width ? i.width + 'px' : 'inherit'
          };background:${color}`"
        >
          {{ i.text }}
        </v-card-title>
        <v-card-text
          class="grey--text text--darken-4"
          :class="parseAttrs('noPadding') ? 'px-0' : 'px-2'"
        >
          <slot :name="i.value" />
        </v-card-text>
      </v-card>
    </div>

    <v-list-item
      v-show="$attrs.action"
      class="justify-start elevation-0 align-center"
    >
      <v-btn icon x-small style="background: #00b1db" @click="$attrs.action">
        <v-icon color="white">mdi-plus</v-icon>
      </v-btn>
    </v-list-item>
  </v-card>

  <!-- <v-card class="elevation-5" width="320" height="fit-content" tile>
    <v-card-title class="SettingTitle">
      {{ content.title }}
    </v-card-title>
    <v-list class="pa-0">
      <v-list-item
        class="justify-center elevation-1 align-center"
        v-for="(item, idx) of content.items"
        :key="idx"
      >
        <div v-if="edit_status == false" class="itemStyle">
          <span>{{ item.text }}</span>
          <span>
            {{ item.value }}
          </span>
        </div>
        <div v-if="edit_status == true" class="itemStyle">
          <v-text-field
            v-model="item.text"
            class="textfieldStyle"
          ></v-text-field>
          <v-text-field
            class="fieldStyle"
            dense
            outlined
            hide-details
            v-model="item.value"
          >
          </v-text-field>
        </div>
        <v-icon v-if="edit_status == true" class="ml-1" @click="remove(idx)"
          >mdi-delete</v-icon
        >
      </v-list-item>
      <v-list-item
        v-if="edit_status == true"
        class="justify-start elevation-1 align-center"
      >
        <div>
          <v-btn icon x-small class="btnStyle ml-9" @click="add_list()">
            <v-icon color="white">mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-list-item>
    </v-list>
  </v-card> -->
</template>
<script>
import { attrsMixin } from "@/helper";
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        { text: "Y分數區間", value: "yZone" },
        { text: "對應類型", value: "refType" },
        { text: "CR", value: "cr" },
      ],
    },
    color: {
      type: String,
      default: "#00b1db",
    },
    minWidth: {
      type: Number,
      default: 180,
    },
  },
  mixins: [attrsMixin],
  data() {
    return {
      // items: [
      //   { text: "Y分數區間", value: "yZone" },
      //   { text: "對應類型", value: "refType" },
      //   { text: "CR", value: "cr" },
      // ],
    };
  },
  mounted() {
    console.log(this.$attrs);
  },
};
</script>
<style scoped>
/* .cardTextStyle {
  height: 100px;
}
.itemStyle {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 55px;
}
.btnStyle {
  background-color: #00b1db;
} */
.settingTitle {
  color: white;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}
.formTable {
  width: fit-content;
}
/* .fieldStyle {
  max-width: 80px;
}
.textfieldStyle {
  max-width: 50px;
} */
</style>
