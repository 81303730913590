<template>
  <v-card
    v-if="!['GRDesc', 'RFDesc'].includes(titleKey)"
    class="elevation-1"
    min-width="185"
    height="fit-content"
    tile
  >
    <v-card-title class="settingTitle text-no-wrap py-2">
      {{ transTitle[titleKey] }}
      <v-icon
        v-if="['gr', 'rf'].includes(titleKey) == true"
        dark
        size="20"
        class="ml-1"
        @click="openDesc = true"
      >
        mdi-format-list-bulleted-square
      </v-icon>
    </v-card-title>
    <v-card-text class="px-2">
      <v-list class="pa-0">
        <v-list-item v-if="weightKey != null" class="justify-center">
          <div v-if="!edit_status" class="itemStyle w-100 px-4">
            <span>比重</span>
            <span>
              {{ parseFloat(weight).toFixed(2) }}
            </span>
          </div>
          <div v-if="edit_status" class="itemStyle">
            <v-text-field
              label="比重"
              class="inputStyle mr-2"
              hide-details
              disabled
            ></v-text-field>
            <v-text-field
              v-model="childWeight"
              class="inputStyle"
              type="number"
              min="0"
              max="1"
              step="0.01"
              hide-details
              @blur="$emit('updateWeight', childWeight)"
            ></v-text-field>
            <div class="w-10 text-right">
              <v-icon v-if="edit_status" class="ml-6" dark>mdi-delete</v-icon>
            </div>
          </div>
        </v-list-item>
        <v-list-item v-for="(item, idx) of content" :key="idx">
          <div v-if="!edit_status" class="itemStyle w-100 px-4">
            <div>{{ item.text }}</div>
            <div>
              {{ parseFloat(item.value).toFixed(2) }}
            </div>
          </div>
          <div v-if="edit_status" class="itemStyle">
            <v-text-field
              v-model="item.text"
              class="inputStyle mr-2"
              hide-details
            ></v-text-field>
            <v-text-field
              class="inputStyle"
              hide-details
              v-model="item.value"
              type="number"
              step="0.01"
              min="0.00"
              @blur="item.value = parseFloat(item.value).toFixed(2)"
            >
            </v-text-field>
            <div class="w-10 text-right">
              <v-icon @click="remove(idx)">mdi-delete</v-icon>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-list-item v-if="edit_status" class="justify-start align-center">
      <v-btn icon x-small class="btnStyle ml-3" @click="add_list()">
        <v-icon color="white">mdi-plus</v-icon>
      </v-btn>
    </v-list-item>
    <allocation-desc
      :open="openDesc"
      :Tdesc="desc"
      @submit="save"
      :Tkey="titleKey"
      @close="openDesc = false"
    ></allocation-desc>
  </v-card>
</template>
<script>
import AllcationDesc from "./AllocationDesc.vue";
export default {
  components: {
    "allocation-desc": AllcationDesc,
  },
  props: {
    content: {
      type: Array,
      default: {},
    },
    edit_status: {
      type: Boolean,
      default: false,
    },
    titleKey: {
      type: String,
      default: "",
    },
    weightKey: {
      type: String,
      default: null,
    },
    weight: {
      type: Number,
      default: null,
    },
    desc: {
      type: String,
      default: null,
    },
  },
  watch: {
    edit_status: function (val) {
      console.log(val);
      this.childWeight = this.weight;
    },
  },
  data() {
    return {
      childContent: {},
      transTitle: {
        si: "可持續性影響評級(SI)",
        ri: "聲譽影響評級(RI)",
        fi: "財務影響評級(FI)",
        pi: "影響可能性(PI)",
        gr: "集團最高風險(GR)",
        inherentRisk: "固有風險等級",
        controlMeasure: "控制措施等級",
        rf: "監管重點領域(RF)",
        ia1: "內稽",
        ia2: "風控",
        ia3: "法遵",
        ia4: "內控自評",
        ia5: "內控運作",
        ia6: "重大風險事件",
        oa1: "主管機關裁罰",
        oa2: "主管機關意見",
        oa3: "其他(含會計師)",
        oa4: "金控缺失",
      },
      openDesc: false,
      childWeight: null,
    };
  },
  mounted() {
    this.childContent = this.content;
    // console.log(this.weight);
    this.childWeight = this.weight;
  },
  // updated() {
  //   this.childWeight = Number(this.weight);
  // },
  methods: {
    add_list() {
      this.content.push({ text: "", value: 0 });
    },
    remove(idx) {
      this.content.splice(idx, 1);
    },
    save() {
      this.$emit("saveDesc", arguments[0]);
      this.openDesc = false;
    },
    saveContent() {
      this.$emit("saveContent", this.childContent);
    },
  },
};
</script>
<style scoped lang="scss">
* {
  font-size: 14px;
}
.cardTextStyle {
  height: 100px;
}
.itemStyle {
  display: flex;
  justify-content: space-between;
  align-items: end;
  // min-height: 55px;
}
.btnStyle {
  background-color: #00b1db;
}
.settingTitle {
  background-color: #0095b8;
  color: white;
  justify-content: center;
  align-items: flex-end;
  font-size: 14px;
  font-weight: bold;
}
.fieldStyle {
  max-width: 80px;
}
.textfieldStyle {
  max-width: 50px;
}
.inputStyle {
  max-width: 50px;
  &:deep(.v-text-field__slot input) {
    text-align: center;
  }
}
</style>
