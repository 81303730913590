<template>
  <v-container fluid>
    <v-row class="mb-3" justify="space-between" align="baseline">
      <v-col class="titleWidth">
        <version-status-title
          title="配分設定｜"
          :versionData="versionData"
        ></version-status-title>
      </v-col>
      <v-col v-if="!isFromSignOff()" class="d-flex justify-end align-center">
        <div v-if="!edit">
          <v-btn @click="getHistory" text color="primary" class="mr-5 pa-0"
            >簽核軌跡</v-btn
          >
          <v-btn
            class="mr-2"
            depressed
            color="#CACACA"
            to="/allocation/management"
            >返回列表</v-btn
          >
          <v-btn
            v-if="versionData.status == 0 && rolesCheck('allocationByIdStatus')"
            @click="changeStatus(1)"
            depressed
            class="mr-2"
            color="primary"
            >製作初稿</v-btn
          >
          <v-btn
            v-if="versionData.status == 1 && rolesCheck('allocationByIdStatus')"
            @click="changeStatus(0)"
            depressed
            class="mr-2 noFocus"
            color="primary"
            outlined
            >修改</v-btn
          >
          <v-btn
            v-if="versionData.status == 0 && rolesCheck('allocationByIdEdit')"
            class="mr-2"
            color="primary"
            @click="edit = true"
            >編輯</v-btn
          >
        </div>

        <v-btn v-else class="mr-2" color="success" @click="save()">儲存</v-btn>
      </v-col>
    </v-row>
    <!-- 配分設定功能 -->
    <!-- <div class="pa-3"> -->
    <!-- IRS -->
    <div class="d-flex align-baseline mb-2">
      <h2 class="fubonGreen--text mr-2 text-h3">固有風險 IRS</h2>
      <span class="text-h6">計算說明:IRS=(SI+RI+FI)*PI*GR</span>
    </div>
    <div class="d-flex mb-4 flex-wrap">
      <div v-for="key in irsList" :key="key">
        <allocation-form
          v-if="key != 'inherentRisk'"
          class="mr-2 mb-2"
          :content="allocationSettings[key]"
          :titleKey="key"
          :edit_status="edit"
          :desc="
            ['gr', 'rf'].includes(key) == true
              ? allocationSettings[key + 'Desc']
              : null
          "
          @submit="allocationSettings[key + 'Desc']"
          @saveDesc="allocationSettings[key + 'Desc'] = arguments[0]"
        >
        </allocation-form>
        <allocation-form-table
          v-else
          :color="
            ['controlMeasure', 'inherentRisk', 'rrs'].includes(key)
              ? '#4CAF50'
              : '#0095B8'
          "
          :items="[{ text: '固有風險等級', value: 'period' }]"
          :action="edit ? addlist(key) : false"
          :minWidth="256"
        >
          <template #period>
            <v-list class="pa-0">
              <v-list-item
                class="justify-space-between"
                v-for="(item, idx) of allocationSettings[key]"
                :key="idx"
              >
                <div v-if="edit" class="formTableItem">
                  <v-text-field
                    class="formTableItem_input mr-2"
                    v-model="item.min"
                    min="0.0"
                    disabled
                    hide-details
                  >
                  </v-text-field>
                  <v-text-field
                    class="formTableItem_input"
                    v-model="item.max"
                    type="number"
                    step="0.1"
                    min="0.0"
                    hide-details
                    @change="changeUpper(key, idx)"
                    @blur="item.max = parseFloat(item.max).toFixed(2)"
                  >
                  </v-text-field>
                </div>
                <div v-else class="formTableItem mr-5">
                  <span>{{ parseFloat(item.min).toFixed(2) }}</span>
                  <span>{{ parseFloat(item.max).toFixed(2) }}</span>
                </div>
                <v-divider
                  class="mx-4"
                  vertical
                  :class="idx == 0 ? 'mt-4' : ''"
                ></v-divider>
                <div v-if="edit" class="formTableItem">
                  <v-text-field
                    class="formTableItem_input mr-3"
                    v-model="item.grade"
                    hide-details
                  ></v-text-field>
                  <v-icon @click="removeList(key, idx)">mdi-delete</v-icon>
                </div>
                <div v-else class="formTableRight text-center">
                  <span>{{ item.grade }}</span>
                </div>
              </v-list-item>
            </v-list>
          </template>
        </allocation-form-table>
      </div>
    </div>
    <!-- CM -->
    <div class="d-flex align-baseline mb-2">
      <h2 class="fubonGreen--text mr-2 text-h3">控制措施 CM</h2>
      <span class="text-h6">計算說明:CM=RF*CR，IA-OA=Y經級距轉換得CR</span>
    </div>
    <div class="d-flex mb-2 flex-wrap">
      <div v-for="key in cmList" :key="key">
        <div style="max-width: 600px">
          <allocation-form-table
            class="mr-2 mb-2"
            v-if="key == 'cm'"
            :color="
              ['controlMeasure', 'inherentRisk', 'rrs'].includes(key)
                ? '#4CAF50'
                : '#0095B8'
            "
            :items="[
              { text: 'Y分數區間', value: 'yZone', width: 160 },
              { text: '對應類型', value: 'matchType', width: 200 },
              { text: 'CR', value: 'cr' },
            ]"
            :action="edit ? addlist(key) : false"
            :minWidth="450"
            noPadding
          >
            <template #yZone>
              <v-list class="pa-0">
                <v-list-item
                  class="justify-space-between px-0"
                  v-for="(item, idx) of allocationSettings[key]"
                  :key="idx"
                >
                  <div v-if="edit" class="formTableItem">
                    <v-text-field
                      class="formTableItem_input mr-2 pl-5"
                      v-model="item.min"
                      min="0.0"
                      disabled
                      hide-details
                    >
                    </v-text-field>
                    <v-text-field
                      class="formTableItem_input"
                      v-model="item.max"
                      type="number"
                      step="0.1"
                      min="0.0"
                      hide-details
                      @change="changeUpper(key, idx)"
                    >
                    </v-text-field>
                  </div>
                  <div v-else class="formTableItem w-50">
                    <span class="pl-8">{{
                      parseFloat(item.min).toFixed(2)
                    }}</span>
                    <span class="pr-4">{{
                      parseFloat(item.max).toFixed(2)
                    }}</span>
                  </div>
                  <v-divider
                    vertical
                    style="z-index: 1"
                    class="ml-4"
                    :class="idx == 0 ? 'mt-4' : ''"
                  ></v-divider>
                </v-list-item>
              </v-list>
            </template>

            <template #matchType>
              <v-list class="pa-0">
                <v-list-item
                  class="justify-start"
                  v-for="(item, idx) of allocationSettings[key]"
                  :key="idx"
                >
                  <div v-if="edit == false">{{ item.type }}</div>
                  <div v-else>
                    <v-text-field
                      v-model="item.type"
                      hide-details
                    ></v-text-field>
                  </div>
                </v-list-item>
              </v-list>
            </template>
            <template #cr>
              <v-list class="pa-0">
                <v-list-item
                  class="justify-center"
                  v-for="(item, idx) of allocationSettings[key]"
                  :key="idx"
                >
                  <div v-if="edit" class="formTableItem">
                    <v-text-field
                      v-model="item.cr"
                      hide-details
                      class="formTableItem_input mr-3"
                    ></v-text-field>
                    <v-icon @click="removeList(key, idx)">mdi-delete</v-icon>
                  </div>
                  <div v-else class="formTableRight text-right">
                    <span>{{ parseFloat(item.cr).toFixed(2) }}</span>
                  </div>
                </v-list-item>
              </v-list>
            </template>
          </allocation-form-table>
        </div>
        <allocation-form
          class="mr-2 mb-2"
          v-if="key == 'rf'"
          :content="allocationSettings[key]"
          :titleKey="key"
          :edit_status="edit"
          :desc="
            ['gr', 'rf'].includes(key) == true
              ? allocationSettings[key + 'Desc']
              : null
          "
          @submit="allocationSettings[key + 'Desc']"
          @saveDesc="allocationSettings[key + 'Desc'] = arguments[0]"
        >
        </allocation-form>
        <allocation-form-table
          v-if="key == 'controlMeasure'"
          :color="
            ['controlMeasure', 'inherentRisk', 'RRS'].includes(key)
              ? '#4CAF50'
              : '#0095B8'
          "
          :items="[{ text: '控制措施等級', value: 'period' }]"
          :action="edit ? addlist(key) : false"
          :minWidth="256"
        >
          <template #period>
            <v-list class="pa-0">
              <v-list-item
                class="justify-space-between"
                v-for="(item, idx) of allocationSettings[key]"
                :key="idx"
              >
                <div v-if="edit" class="formTableItem">
                  <v-text-field
                    v-model="item.min"
                    min="0.0"
                    disabled
                    class="formTableItem_input mr-2"
                    hide-details
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="item.max"
                    type="number"
                    step="0.1"
                    class="formTableItem_input"
                    min="0.0"
                    @change="changeUpper(key, idx)"
                    hide-details
                  >
                  </v-text-field>
                </div>
                <div v-else class="formTableItem mr-5">
                  <span>{{ parseFloat(item.min).toFixed(2) }}</span>
                  <span>{{ parseFloat(item.max).toFixed(2) }}</span>
                </div>

                <v-divider
                  class="mx-4"
                  vertical
                  :class="idx == 0 ? 'mt-4' : ''"
                ></v-divider>
                <div v-if="edit" class="formTableItem">
                  <v-text-field
                    v-model="item.grade"
                    hide-details
                    class="formTableItem_input mr-3"
                  ></v-text-field>
                  <v-icon @click="removeList(key, idx)">mdi-delete</v-icon>
                </div>
                <div v-else class="formTableRight text-center">
                  <span>{{ item.grade }}</span>
                </div>
              </v-list-item>
            </v-list>
          </template>
        </allocation-form-table>
      </div>
    </div>
    <!-- IA -->
    <div class="text-h5 mb-2">內部稽核結果(IA)</div>
    <div v-for="key in iaList" :key="key">
      <div class="d-flex flex-wrap" v-if="key == 'ia'">
        <allocation-form
          v-for="(iaValue, iaKey) in allocationSettings[key]"
          :key="iaKey"
          class="mr-3 mb-2"
          :content="iaValue.content"
          :titleKey="key + (iaKey + 1)"
          :edit_status="edit"
          :weightKey="key + (iaKey + 1)"
          :weight="iaValue.weight"
          @updateWeight="iaValue.weight = Number(arguments[0])"
        >
          <!-- @updateWeight="iaValue.weight = arguments[0]" -->
        </allocation-form>
      </div>
    </div>
    <!-- OA -->
    <div class="text-h5 mb-2">其他審查結果(OA)</div>
    <div v-for="key in oaList" :key="`oa-${key}`">
      <div class="d-flex flex-wrap">
        <allocation-form
          v-for="(oaValue, oaKey) in allocationSettings[key]"
          :key="oaKey"
          class="mr-3 mb-2"
          :content="oaValue.content"
          :titleKey="key + (oaKey + 1)"
          :edit_status="edit"
        >
        </allocation-form>
      </div>
    </div>
    <!-- RRS -->
    <div class="d-flex align-baseline mt-5 mb-2">
      <h2 class="fubonGreen--text mr-2 text-h3">剩餘風險RRS</h2>
      <span class="text-h6">計算說明:RRS=IRS*CM</span>
    </div>
    <div style="max-width: 550px">
      <div v-for="(value, key) in allocationSettings" :key="key">
        <allocation-form-table
          class="mr-2"
          v-if="key == 'rrs'"
          :color="
            ['controlMeasure', 'inherentRisk', 'rrs'].includes(key)
              ? '#4CAF50'
              : '#0095B8'
          "
          :items="[
            { text: '剩餘區間風險', value: 'remainingRisk', width: 180 },
            { text: '評級', value: 'grade' },
            { text: '週期', value: 'cycle' },
            { text: '等級', value: 'level' },
          ]"
          :action="edit ? addlist(key) : false"
          :minWidth="256"
          noPadding
        >
          <template #remainingRisk>
            <v-list class="pa-0">
              <v-list-item
                class="justify-space-between px-0"
                v-for="(item, idx) of value"
                :key="idx"
              >
                <div v-if="edit" class="formTableItem">
                  <v-text-field
                    class="formTableItem_input mr-2 pl-5"
                    v-model="item.min"
                    min="0.0"
                    disabled
                    hide-details
                  >
                  </v-text-field>
                  <v-text-field
                    class="formTableItem_input"
                    v-model="item.max"
                    type="number"
                    step="0.1"
                    min="0.0"
                    hide-details
                    @change="changeUpper(key, idx)"
                  >
                  </v-text-field>
                </div>
                <div v-else class="formTableItem w-50">
                  <span class="pl-8">{{
                    parseFloat(item.min).toFixed(2)
                  }}</span>
                  <span class="pr-4">{{
                    parseFloat(item.max).toFixed(2)
                  }}</span>
                </div>
                <v-divider
                  vertical
                  style="z-index: 1"
                  class="ml-4"
                  :class="idx == 0 ? 'mt-4' : ''"
                ></v-divider>
              </v-list-item>
            </v-list>
          </template>

          <template #grade>
            <v-list class="pa-0">
              <v-list-item
                class="justify-center"
                v-for="(item, idx) of value"
                :key="idx"
              >
                <div v-if="edit == false">{{ item.grade }}</div>
                <div v-else>
                  <v-text-field
                    hide-details
                    class="formTableItem_input"
                    v-model="item.grade"
                  ></v-text-field>
                </div>
              </v-list-item>
            </v-list>
          </template>
          <template #cycle>
            <v-list class="pa-0">
              <v-list-item
                class="justify-center"
                v-for="(item, idx) of value"
                :key="idx"
              >
                <div v-if="edit == false">{{ item.cycle }}</div>
                <div v-else>
                  <v-text-field
                    hide-details
                    class="formTableItem_input"
                    v-model="item.cycle"
                    type="number"
                    min="0.5"
                    max="3"
                    step="0.5"
                    @blur="inputCycle(idx)"
                  ></v-text-field>
                </div>
              </v-list-item>
            </v-list>
          </template>
          <template #level>
            <v-list class="pa-0">
              <v-list-item
                class="justify-center"
                v-for="(item, idx) of value"
                :key="idx"
              >
                <div v-if="edit" class="formTableItem">
                  <v-text-field
                    v-model="item.level"
                    hide-details
                    class="formTableItem_input mr-3"
                  ></v-text-field>
                  <v-icon @click="removeList(key, idx)">mdi-delete</v-icon>
                </div>
                <div v-else class="formTableRight text-center">
                  <span>{{ item.level }}</span>
                </div>

                <!-- 
                <div v-if="!edit">{{ item.level }}</div>
                <div v-else>
                  <v-text-field
                    hide-details
                    v-model="item.level"
                  ></v-text-field>
                </div>
                <div v-if="edit">
                  <v-icon @click="removeList(key, idx)">mdi-delete</v-icon>
                </div> -->
              </v-list-item>
            </v-list>
          </template>
        </allocation-form-table>
      </div>
    </div>
    <!-- === Dialogs === -->
    <v-dialog v-model="errorHintDialog" width="400" persistent>
      <v-card>
        <v-card-title style="background: #0095b8; color: white">
          提示
        </v-card-title>
        <v-card-text
          class="mt-3"
          style="word-break: normal; white-space: pre-line"
        >
          <span>{{ errorHintText }}</span>
        </v-card-text>
      </v-card>
      <v-card-actions>
        <v-spacer />
        <v-btn color="success" @click="errorHintDialog = false">我知道了</v-btn>
      </v-card-actions>
    </v-dialog>
    <!-- 簽核軌跡 -->
    <dialog-history
      :dialog="signHistoryDialog"
      :historyData="historyData"
      @close="signHistoryDialog = false"
    ></dialog-history>
    <!-- 儲存成功 -->
    <material-dialog
      v-model="saveSuccessDialog"
      title="儲存成功"
      max-width="400"
    >
      單位版本
      <chip-grey
        >{{ versionData.year }}-{{ versionText(versionData.no) }}</chip-grey
      >
      已成功儲存
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="saveSuccessDialog = false"
          >我知道了</v-btn
        >
      </template>
    </material-dialog>
    <!-- </div> -->
  </v-container>
</template>
<script>
import { sync } from "vuex-pathify";
import AllocationForm from "./components/AllocationForm.vue";
import AllocationFormTable from "./components/AllocationFormTable.vue";
import {
  versionMixin,
  floatCalculatorMixin,
  fromSignOffMixin,
  checkRolesMixin,
} from "@/helper";
import {
  apiSaveAllocationSettings,
  apiGetAllocationSettingsById,
  apiGetAllocationHistory,
  apiAllocationStatus,
} from "../../api/allocation";
import { apiGetHistory } from "../../api/risk-type";
export default {
  name: "AllocationSetting",
  mixins: [
    versionMixin,
    floatCalculatorMixin,
    fromSignOffMixin,
    checkRolesMixin,
  ],
  components: {
    "allocation-form": AllocationForm,
    "allocation-form-table": AllocationFormTable,
  },
  data() {
    return {
      item: {
        id: 1,
        year: "2022",
        version: 2,
        status: "已儲存",
        startDay: "2022-07-01",
        updatedAt: "2022-06-18 14:57:49",
        createdAt: "2022-06-18 14:33:08",
      },
      allocationId: this.$route.params.id,

      // IRSonList: ["si", "ri", "fi", "pi", "gr"],
      irsList: ["si", "ri", "fi", "pi", "gr", "inherentRisk"],
      oaList: ["oa"],
      iaList: ["ia"],
      cmList: ["cm", "rf", "controlMeasure"],
      rrsList: ["rrs"],

      allocationSettings: {
        si: [],
        ri: [],
        fi: [],
        pi: [],
        gr: [],
        rf: [],
        grDesc: "",
        rfDesc: "",
        ia: [
          {
            text: "內稽",
            weight: null,
            content: [],
          },
          {
            text: "風控",
            weight: null,
            content: [],
          },
          {
            text: "法遵",
            weight: null,
            content: [],
          },
          {
            text: "內控自評",
            weight: null,
            content: [],
          },
          {
            text: "內控運作",
            weight: null,
            content: [],
          },
          {
            text: "重大風險事件",
            weight: null,
            content: [],
          },
        ],
        oa: [
          {
            text: "主管機關裁罰",
            content: [],
          },
          {
            text: "主管機關意見",
            content: [],
          },
          {
            text: "其他(含會計師)",
            content: [],
          },
          {
            text: "金控缺失",
            content: [],
          },
        ],
        cm: [],
        inherentRisk: [],
        controlMeasure: [],
        rrs: [],
      },
      edit: false,
      versionData: { title: "配分設定", year: "2022", No: "01", status: 0 },
      errorHintDialog: false,
      errorHintText: "",

      emptyList: [],
      keyTrans: {
        si: "可持續性影響評級(SI)",
        ri: "聲譽影響評級(RI)",
        fi: "財務影響評級(FI)",
        pi: "影響可能性(PI)",
        gr: "集團最高風險(GR)",
        inherentRisk: "固有風險等級",
        controlMeasure: "控制措施等級",
        rf: "監管重點領域(RF)",
        ia1: "內稽",
        ia2: "風控",
        ia3: "法遵",
        ia4: "內控自評",
        ia5: "內控運作",
        ia6: "重大風險事件",
        oa1: "主管機關裁罰",
        oa2: "主管機關意見",
        oa3: "其他(含會計師)",
        oa4: "金控缺失",
        cm: "控制措施CM",
        rrs: "剩餘風險",
      },
      signHistoryDialog: false,
      historyData: {
        year: "2022",
        No: "2",
        history: [],
      },
      saveSuccessDialog: false,
    };
  },
  mounted() {
    this.getAllocationById();
    // this.get_setting();
  },
  computed: {
    ...sync("global", ["alert"]),
  },
  methods: {
    async changeStatus(status) {
      try {
        let res = await apiAllocationStatus(this.allocationId, { status });
        console.log({ res });
        this.versionData.status = status;
      } catch (err) {
        console.log({ err });
      }
    },
    async save() {
      // 檢查錯誤: true為有錯
      let errMsg = "";
      errMsg += this.checkWeightTotal();
      errMsg += this.check_value();
      errMsg += this.checkPeriodPriority();
      console.log(this.emptyList);
      // // let checkPeriodError = this.checkPeriodPriority();
      // if (
      //   checkEmptyError == true ||
      //   checkWeightError == true
      //   // || checkPeriodError == true
      // ) {
      //   this.edit = true;
      //   return;
      // }
      if (errMsg != "") {
        this.alert = {
          open: true,
          content: errMsg,
        };
        this.edit = true;
        return;
      }
      this.edit = false;
      try {
        let res = await apiSaveAllocationSettings(
          this.allocationId,
          this.allocationSettings
        );
        console.log(res);
      } catch (error) {}
      // Todo allocation save api
      this.saveSuccessDialog = true;

      // // === For Test ===
      // this.edit = false;
    },
    async getHistory() {
      try {
        let res = await apiGetAllocationHistory(this.allocationId);
        this.historyData = res.data;
        this.signHistoryDialog = true;
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    changeUpper(item, idx) {
      if (idx + 2 <= this.allocationSettings[item].length) {
        let count_number2 = +this.allocationSettings[item][idx].max;
        this.allocationSettings[item][idx + 1].min = (
          count_number2 + 0.01
        ).toFixed(2);
      }
    },
    async getAllocationById() {
      // TODO call axios get si_settings
      try {
        let res = await apiGetAllocationSettingsById(this.allocationId);
        this.allocationSettings = res.data.content;
        this.versionData = res.data.allocation;
        if (this.allocationSettings["oa"] == null) {
          let oaData = [
            { text: "主管機關裁罰", content: [] },
            { text: "主管機關意見", content: [] },
            { text: "其他(含會計師)", content: [] },
            { text: "金控缺失", content: [] },
          ];
          this.$set(this.allocationSettings, "oa", oaData);
        } else {
          if (this.allocationSettings["oa"].length < 4) {
            let oaData = [
              { text: "主管機關裁罰", content: [] },
              { text: "主管機關意見", content: [] },
              { text: "其他(含會計師)", content: [] },
              { text: "金控缺失", content: [] },
            ];
            this.$set(this.allocationSettings, "oa", oaData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    addlist(key) {
      let count_number = () => {
        if (["inherentRisk", "controlMeasure"].includes(key)) {
          let checkLength = this.allocationSettings[key].length;
          if (checkLength >= 1) {
            this.allocationSettings[key].push({
              min: (
                +this.allocationSettings[key][checkLength - 1].max + 0.01
              ).toFixed(2),
              max: null,
              grade: null,
            });
          } else {
            this.allocationSettings[key].push({
              min: (0.0).toFixed(2),
              max: null,
              grade: null,
            });
          }
        } else if (key == "cm") {
          let checkLength = this.allocationSettings[key].length;
          if (checkLength >= 1) {
            this.allocationSettings[key].push({
              min: (
                +this.allocationSettings[key][checkLength - 1].max + 0.01
              ).toFixed(2),
              max: null,
              type: null,
              cr: null,
            });
          } else {
            this.allocationSettings[key].push({
              min: (-10.0).toFixed(2),
              max: null,
              type: null,
              cr: null,
            });
          }
        } else if (key == "rrs") {
          let checkLength = this.allocationSettings[key].length;
          if (checkLength >= 1) {
            this.allocationSettings[key].push({
              min: (
                +this.allocationSettings[key][checkLength - 1].max + 0.01
              ).toFixed(2),
              max: null,
              grade: null,
              cycle: null,
              level: null,
            });
          } else {
            this.allocationSettings[key].push({
              min: (0.0).toFixed(2),
              max: null,
              grade: null,
              cycle: null,
              level: null,
            });
          }
        }
      };
      return count_number;
    },
    removeList(key, idx) {
      this.allocationSettings[key].splice(idx, 1);
    },
    // 檢查空值
    check_value() {
      let empty_exist = false;
      let err = "";
      this.emptyList = [];
      for (let key in this.allocationSettings) {
        if (key == "ia") {
          for (let [index, item] of this.allocationSettings[key].entries()) {
            console.log(key + (index + 1));
            if ([undefined, null, ""].includes(item.weight)) {
              empty_exist = true;
              if (this.emptyList.indexOf(key + (index + 1)) === -1) {
                this.emptyList.push(key + (index + 1));
              }
            }
            for (let [index2, item2] of item.content.entries()) {
              if (
                [undefined, null, ""].includes(item2.value) ||
                [undefined, null, ""].includes(item2.text)
              ) {
                if (this.emptyList.indexOf(key + (index + 1)) === -1) {
                  this.emptyList.push(key + (index + 1));
                }
              }
            }
          }
        } else if (key == "oa") {
          for (let [index, item] of this.allocationSettings[key].entries()) {
            for (let [index2, item2] of item.content.entries()) {
              if (
                [undefined, null, ""].includes(item2.value) ||
                [undefined, null, ""].includes(item2.text)
              ) {
                if (this.emptyList.indexOf(key + (index + 1)) === -1) {
                  this.emptyList.push(key + (index + 1));
                }
              }
            }
          }
        } else if (
          ["controlMeasure", "inherentRisk", "rrs", "cm"].includes(key)
        ) {
          if (this.allocationSettings[key].length == 0) {
            empty_exist = true;
            if (this.emptyList.indexOf(key) === -1) {
              this.emptyList.push(key);
            }
          }
          for (let [idx, item] of this.allocationSettings[key].entries()) {
            for (let name in item) {
              if ([undefined, null, ""].includes(item[name])) {
                empty_exist = true;
                if (this.emptyList.indexOf(key) === -1) {
                  this.emptyList.push(key);
                }
              }
            }
          }
        } else {
          if (!["rfDesc", "grDesc"].includes(key)) {
            for (let el of this.allocationSettings[key]) {
              if (["", null, undefined].includes(el.text) || el.value == null) {
                empty_exist = true;
                if (this.emptyList.indexOf(key) === -1) {
                  this.emptyList.push(key);
                }
              }
            }
          }
        }
      }
      if (empty_exist == true) {
        // this.errorHintDialog = true;
        // this.errorHintText = "項目內皆為必填，請確認各項數值有無填寫 \n\n";
        // for (let [idx, item] of this.emptyList.entries()) {
        //   this.errorHintText += idx + 1 + ". " + this.keyTrans[item] + "\n";
        // }
        err = "項目內皆為必填，請確認各項數值有無填寫 <br/>";
        for (let [idx, item] of this.emptyList.entries()) {
          err += idx + 1 + ". " + this.keyTrans[item] + "<br/>";
        }
        // this.alert = {
        //   open: true,
        //   content: text,
        // };
      }
      return err;
    },
    // 檢查占比總和是否為 1.0
    checkWeightTotal() {
      let err = "";
      let weightTotal = 0;
      for (let [idx, item] of this.allocationSettings["ia"].entries()) {
        weightTotal = this.floatAdd(weightTotal, item.weight);
      }

      weightTotal = Number(weightTotal);
      console.log(weightTotal);
      if (weightTotal != 1.0) {
        // this.errorHintDialog = true;
        // this.errorHintText = "比重總額需等於1.0";
        // this.alert = {
        //   open: true,
        //   content: "比重總額需等於1.0",
        // };
        err = "<p>比重總額需等於1.0<p/>";
      }
      return err;
    },
    placeTwo(value) {
      console.log(value);
      let toPlaceTwo =
        ("" + value)
          .replace(/[^d^\.]+/g, "")
          .replace(/^0+(\d)/, "$1")
          .replace(/^\./, "0.")
          .match(/^d*(\.?\d{0,2})/g)[0] || "";
      console.log(value);
    },
    // 檢查上下限是否符合規則
    checkPeriodPriority() {
      let periodError = false;
      let err = "";
      for (let key in this.allocationSettings) {
        if (
          ["controlMeasure", "inherentRisk", "rrs", "cm"].includes(key) == true
        ) {
          if (this.allocationSettings[key].length == 0) {
            continue;
          }
          for (let [idx, item] of this.allocationSettings[key].entries()) {
            if (idx + 1 == this.allocationSettings[key].length) {
              continue;
            }
            if (
              +item.max > +this.allocationSettings[key][idx + 1].max ||
              +item.max == +item.min
            ) {
              periodError = true;
              if (this.emptyList.indexOf(key) === -1) {
                this.emptyList.push(key);
              }
            }
          }
        }
      }
      if (periodError == true) {
        // this.errorHintDialog = true;
        err = "區間數值不得低於前一區間且結束區間不能等於起始區間 <br/>";
        for (let [idx, item] of this.emptyList.entries()) {
          err += idx + 1 + ". " + this.keyTrans[item] + "<br/>";
        }
        // this.edit = true;
      }
      return err;
    },
    // 判斷週期最大值最小值
    inputCycle(idx) {
      if (this.allocationSettings["rrs"][idx].cycle >= 3) {
        this.allocationSettings["rrs"][idx].cycle = 3;
      } else if (this.allocationSettings["rrs"][idx].cycle < 0) {
        this.allocationSettings["rrs"][idx].cycle = 0;
      }
    },
    updateWeightP() {
      let tmp = Number(arguments[0]);
      console.log(tmp);
    },
  },
};
</script>
<style scoped lang="scss">
* {
  font-size: 14px;
}
.small {
  font-size: 12px;
}
.titleWidth {
  min-width: 425px;
  max-width: 300px;
}
.titleStyle2 {
  min-width: 450px;
  max-width: 480px;
}
.formTableItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;

  &_input {
    // max-width: 48x;
    width: 48px;
    &:deep(.v-text-field__slot input) {
      text-align: center;
    }
  }
  span {
    display: block;
    // min-width: 56px;
    text-align: right;
  }
}
.formTableRight {
  span {
    display: block;
    width: 40px;
    // text-align: center;
  }
}
/* .fubonGreen--text {
  color: #00a29d;
} */
/* .greyStyle {
  color: grey;
  align-items: flex-end;
}
.titleStyle1 {
  width: 30vw;
  display: flex;
  align-items: self-end;
  justify-content: space-between;
}
.titleStyle2 {
  width: 35%;
  display: flex;
  align-items: self-end;
  justify-content: space-between;
}
.childTitle1 {
  font-size: 20px;
  align-items: self-end;
}
.test {
  display: flex;
} */
</style>
